import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Mixpanel } from "../../utils/mixpanel";
export class TouchableOpacityLink extends Component {
  render() {
    let { to, ...restOfProps } = { ...this.props };
    let restOfObj = {};
    if (typeof to == "string") {
      restOfObj = { pathname: to };
    } else if (typeof to == "object") {
      restOfObj = { ...to };
    } else if (typeof to == "function") {
      restOfObj = to(location);
    }
    return (
      <Link
        to={(location) => {
          const path = restOfObj.pathname || this.props.to;
    
          // frontendv2
          if (this.shouldHardRefresh(path)) {
            return undefined;
          }
    
          return { prevPath: location.pathname, ...restOfObj };
        }}
        {...restOfProps}
        onClick={() => this.handleClick(window.location.pathname)}
      />
    );
  }

  shouldHardRefresh = (path) => {
    const refreshRegex = /^\/(chaos|business|internet|yesterday|state-of-the-unicorns|about-us)(\/.+)?$/;
    return refreshRegex.test(path);
  };


  handleClick = (location) => {
    try {
      Mixpanel.track("Page link clicked", {
        pagename: this.props.to,
        from_page: location,
      });
    } catch (error) {
      console.log("mix panel trackin error =>", error);
    }

    // Perform a hard refresh if the path matches the regex
    const path = typeof this.props.to === "string" ? this.props.to : this.props.to?.pathname;
    if (this.shouldHardRefresh(path) && process.browser) {
      window.location.href = path;
      return;
    }

    if(process.browser && (typeof this.props?.to == 'string') && (this.props.moreStoriesSection || this.props.youMayFindInterestingSection)){
      return window.location.href = this.props.to
    }
    if(process.browser && (typeof this.props?.to?.pathname == 'string') && (this.props.moreStoriesSection || this.props.youMayFindInterestingSection)){
      return window.location.href = this.props.to?.pathname
    }
    if (this.props.handleClick) {
      this.props.handleClick(location, this.props.to);
    }
    // if (this.props?.to?.pathname) {
    //   window.location.href = this.props.to.pathname;
    // }
  };
}

export default TouchableOpacityLink;
